export default [
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
  {
    path: "",
    component: () => import("@/layouts/MainLayout"),
    meta: {
      requiresAuth: false,
    },
    children: [
      { path: "", redirect: "home" },
      {
        path: "home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "main" */ "../pages/HomePage"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "main" */ "../pages/AboutPage"),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "",
    component: () => import("@/layouts/DefaultLayout"),
    children: [
      {
        path: "404",
        name: "404",
        component: () =>
          import(/* webpackChunkName: "404" */ "@/pages/404Page"),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];
